<template>
  <div class="relative" :class="{ [custom]: !!custom }">
    <label v-if="!!label || $slots.label" class="text-13 block text-gray-500" :for="uid">
      <slot name="label">
        {{ label }}
      </slot>
    </label>

    <div class="relative">
      <Field :id="uid" v-slot="{ field }" v-model="inputField" :name="name" :rules="rules" validate-on-input>
        <input
          v-bind="field"
          :type="type"
          :placeholder="placeholder"
          :disabled="disabled"
          :readonly="readonly"
          :autocomplete="autocomplete"
          class="block border h-32 focus:border-2 px-12 pb-2 pt-4 w-full text-15 text-gray-900 bg-white rounded-4 border-1 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-primary-600 peer"
          :class="{
            '!border-red-500': !!hasErrors,
            '!border-gray-200 !bg-gray-50 !text-gray-300': disabled,
            [custom]: !!custom,
          }"
          @change="$emit('change', $event)"
          @blur="$emit('blur', $event)"
        />
        <span v-if="error" class="text-11 absolute left-0 top-full pt-0 text-red-500" v-text="error" />
      </Field>
    </div>

    <div v-if="suffixIcon" class="absolute right-0 top-[70%] transform -translate-y-1/2 py-2 px-12 text-primary-800">
      <i
        class="text-13"
        :class="{ [suffixIcon]: !!suffixIcon, 'cursor-pointer': suffixIcon !== 'fas fa-search' }"
        @click="$emit('suffix-clicked')"
      />
    </div>
  </div>
</template>

<script>
import { Field } from 'vee-validate';
import { computed, ref, watch } from 'vue';

export default {
  name: 'SparkInput',

  components: { Field },

  props: {
    autocomplete: { type: HTMLInputElement['autocomplete'], default: 'off' },
    disabled: { type: Boolean },
    label: { type: String, default: '' },
    name: { type: String, required: true },
    placeholder: { type: String, default: '' },
    readonly: { type: Boolean },
    rules: { type: [Object, String, Function], default: '' },
    type: { type: String, default: 'text' },
    suffixIcon: { type: String, default: '' },
    topLabel: { type: Boolean },
    error: { type: String, default: '' },
    custom: { type: String, default: '' },
    isPercent: { type: Boolean },
    modelValue: { type: [Number, String, null, File], default: '' },
  },

  emits: ['update:modelValue', 'suffix-clicked', 'change', 'blur'],

  setup(props, { emit }) {
    const inputField = computed({
      get: () => {
        if (props.isPercent && typeof props.modelValue === 'number' && props.modelValue <= 1) {
          return (props.modelValue * 100).toString();
        }
        return props.modelValue;
      },
      set: value => {
        if (props.isPercent) {
          const numValue = parseFloat(value);
          if (!isNaN(numValue)) {
            emit('update:modelValue', numValue / 100);
          } else {
            emit('update:modelValue', value);
          }
        } else {
          emit('update:modelValue', value);
        }
      },
    });

    const uid = computed(() => `input-${Math.random().toString(36).substring(2, 9)}`);

    const hasErrors = ref(false);
    watch(
      () => props.error,
      () => {
        hasErrors.value = props.error ? true : false;
      },
      { immediate: true }
    );

    const items = computed(() => {
      return props.options.map(item => {
        if (typeof item === 'object') return item;
        return { label: item, value: item };
      });
    });

    return { inputField, items, uid, hasErrors };
  },
};
</script>

<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
