<template>
  <SparkSpinner v-if="loading" class="self-center" />
  <div v-else-if="hasFilters" class="flex flex-col gap-8">
    <div class="flex justify-between items-center">
      <div class="text-13 font-semibold">Options</div>
      <SparkButton custom="!text-11 text-gray-500" small variant="plain" @click="resetProperties">
        <div v-text="'Clear All'" />
      </SparkButton>
    </div>
    <div v-for="(description, index) in chainDescriptions" :key="index" class="flex flex-col gap-2">
      <SparkSelect
        v-model="formData[description.label]"
        :options="description.values"
        :label="description.label"
        :name="description.label"
        @change="filterAndUpdateChains"
      />
      <SparkButton
        v-if="formData[description.label]"
        class="self-end"
        custom="!text-11 !p-0 !m-0"
        small
        variant="plain"
        @click="resetProperty(description.label)"
      >
        <div v-text="`Clear ${description.label}`" />
      </SparkButton>
    </div>
  </div>
  <div v-else class="font-semibold text-11 self-center" v-text="'No options available'" />
</template>

<script>
import { mapState, mapActions } from 'vuex';

import SparkSpinner from '../../../components/SparkComponents/SparkSpinner.vue';

import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkSelect from '@/components/SparkComponents/SparkSelect.vue';

export default {
  name: 'ChainDescriptionOptions',

  components: { SparkSelect, SparkButton, SparkSpinner },

  props: {
    part: { type: Object, default: () => {} },
    partIds: { type: Array, default: () => [] },
  },

  emits: ['option-selected'],

  data() {
    return {
      chainDescriptions: [],
      formData: {},
      hasFilters: false,
      loading: true,
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),

    isMultipleParts() {
      return !!this.partIds.length;
    },
  },

  watch: {
    formData: {
      deep: true,
      handler() {
        this.filterAndUpdateChains();
      },
    },
  },

  async mounted() {
    this.fetchPartDetails().then(() => {
      this.fetchInitialData();
    });
  },

  methods: {
    ...mapActions('prp', ['fetchPrpOrderData']),

    async fetchPartDetails() {
      try {
        const response = await this.axiosInstance.get(`/api/v1/prp-part/${this.part.part_id}/`);
        this.partDescriptions = response.data.part_description_items;
        this.partDescriptions.forEach(descItem => {
          this.formData[descItem.descriptionLabel] = descItem.value;
        });
      } catch (error) {
        console.error('Error fetching part details:', error);
      }
    },

    async fetchInitialData() {
      const params = new URLSearchParams();
      Object.entries(this.formData).forEach(([key, value]) => {
        if (value) {
          params.append(`${key}`, value);
        }
      });
      try {
        const response = await this.axiosInstance.get(`/api/v1/prp-filter-properties/${this.part.part_id}/`, {
          params,
        });
        this.chainDescriptions = response.data.filtered_properties.sort((a, b) => a.order - b.order);
        this.loading = false;
        if (this.chainDescriptions.length) {
          this.hasFilters = true;
        }
      } catch (error) {
        console.error('Failed to load initial data', error);
      }
    },

    async updatePartProperties() {
      const propertiesToUpdate = {};
      Object.keys(this.formData).forEach(key => {
        if (this.formData[key]) {
          // Only include non-empty values
          propertiesToUpdate[key] = this.formData[key];
        } else {
          // If it's empty and previously had a value, set it to null or an appropriate value to clear it
          propertiesToUpdate[key] = null;
        }
      });

      try {
        await this.axiosInstance.put(`/api/v1/prp-part/${this.part.part_id}/`, {
          properties: propertiesToUpdate,
        });
        await this.fetchPartDetails(); // To refresh the part descriptions from the server
      } catch (error) {
        console.error('Error updating part properties:', error);
      }
    },

    resetProperty(label) {
      if (this.isMultipleParts) {
        this.partIds.forEach(async partId => {
          await this.axiosInstance.put(`/api/v1/prp-part/${partId}/`, {
            properties: { [label]: null },
          });
        });
      }
      this.formData[label] = '';
      this.filterAndUpdateChains();
      this.updatePartProperties();
    },

    async resetProperties() {
      if (this.isMultipleParts) {
        this.partIds.forEach(async partId => {
          this.$emit('option-selected', { formData: this.formData, isMultipleParts: this.isMultipleParts });
          await this.axiosInstance.put(`/api/v1/prp-part/${partId}/`, {
            properties: Object.fromEntries(Object.keys(this.formData).map(key => [key, null])),
          });
        });
      }
      Object.keys(this.formData).forEach(key => {
        this.formData[key] = '';
      });
      this.filterAndUpdateChains();
      this.updatePartProperties();
    },

    async filterAndUpdateChains() {
      this.$emit('option-selected', { formData: this.formData, isMultipleParts: this.isMultipleParts });
      const params = new URLSearchParams();
      Object.entries(this.formData).forEach(([key, value]) => {
        if (value) {
          params.append(key, value);
        }
      });
      await this.axiosInstance
        .get(`/api/v1/prp-filter-properties/${this.part.part_id}/`, { params })
        .then(response => {
          this.chainDescriptions = response.data.filtered_properties.sort((a, b) => a.order - b.order);
          this.fetchPrpOrderData();
        })
        .catch(error => console.error('Failed to update filters', error));
    },
  },
};
</script>
