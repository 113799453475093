<template>
  <div v-if="shouldShowCustomerInfo" class="customer-info border border-gray-100 rounded-8 bg-white">
    <div class="border-b border-gray-100 p-24">
      <div class="flex justify-between gap-64">
        <div class="flex flex-col">
          <div class="text-15 m-w-200 font-semibold text-wrap" v-text="'Details'" />
        </div>
        <div class="!min-w-[500px] pt-12">
          <OrderCategorySummary
            v-if="isExpertView"
            :combo-categories="comboCategories"
            :text-categories="textCategories"
          />
          <PrpOrderCategories v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderCategorySummary from '../components/OrderCategorySummary.vue';

import PrpOrderCategories from './PrpOrderCategories.vue';

export default {
  name: 'CustomerInfo',

  components: { PrpOrderCategories, OrderCategorySummary },

  props: {
    isExpertView: { type: Boolean },
    comboCategories: { type: Object, default: () => {} },
    textCategories: { type: Object, default: () => {} },
  },

  computed: {
    shouldShowCustomerInfo() {
      if (this.comboCategories && this.textCategories) {
        return (
          (this.isExpertView &&
            (Object.keys(this.comboCategories).length > 0 || Object.keys(this.textCategories).length > 0)) ||
          !this.isExpertView
        );
      } else {
        return true;
      }
    },
  },
};
</script>
