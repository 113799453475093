<template>
  <div class="flex gap-8 flex-col w-full">
    <div class="flex gap-8 justify-center">
      <div
        class="w-1/2 border rounded-8 border-gray-50 p-20 cursor-pointer hover:border-primary-300 hover:bg-primary-50"
        :class="activeTemplateType === 'part' ? 'border-primary-300 bg-primary-50' : ''"
        @click="setActiveTemplateType('part')"
      >
        <div>Part Document</div>
        <div class="text-13 text-gray-600">You can use any part related data.</div>
      </div>
      <div
        class="w-1/2 border rounded-8 border-gray-50 p-20 cursor-pointer hover:border-primary-300 hover:bg-primary-50"
        :class="activeTemplateType === 'part_list' ? 'border-primary-300 bg-primary-50' : ''"
        @click="setActiveTemplateType('part_list')"
      >
        <div>Multiple Parts Document</div>
        <div class="text-13 text-gray-600">This document may contain data of several parts.</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TypeSelection',

  emits: ['set-active-template-type'],

  data() {
    return {
      activeTemplateType: 'part',
    };
  },

  methods: {
    setActiveTemplateType(type) {
      this.activeTemplateType = type;
      this.$emit('set-active-template-type', type);
    },
  },
};
</script>
