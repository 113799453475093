<template>
  <div class="bg-white rounded-8 border border-gray-50">
    <div class="border-b border-gray-50 flex flex-col gap-8">
      <div class="pl-20 pt-20 text-18">User Account</div>
      <div class="pl-20 pb-20 text-13 font-normal text-gray-400">
        Manage your personal details and subscription preferences
      </div>
    </div>
    <div class="border-b border-gray-50 flex gap-8">
      <div class="text-14 pl-20 pt-20 w-1/5">Personal Information</div>
      <div class="p-20 text-13 gap-8 flex flex-col w-1/5">
        <div>
          <div class="text-gray-400 font-medium">Username</div>
          <div>{{ user.username }}</div>
        </div>
        <div>
          <div class="text-gray-400 font-medium">Name</div>
          <div>{{ user.first_name }} {{ user.last_name }}</div>
        </div>
        <div>
          <div class="text-gray-400 font-medium">Email</div>
          <div>{{ user.email }}</div>
        </div>
      </div>
      <div class="p-20 text-13 gap-8 flex flex-col w-1/5">
        <div>
          <div class="text-gray-400 font-medium">Role</div>
          <div>{{ userRoleVerbose }}</div>
        </div>
        <div>
          <div class="text-gray-400 font-medium">Organization</div>
          <div>{{ user.organization.name }}</div>
        </div>
      </div>
      <div class="p-20 text-13 gap-8 flex flex-col">
        <div v-if="profilePic" class="text-gray-400 font-medium">
          Profile Picture
          <i class="fas fa-trash mr-8 hover:cursor-pointer hover:text-gray-700" @click="deleteProfilePic" />
          <i class="fas fa-edit hover:cursor-pointer hover:text-gray-700" @click="showDialog()" />
        </div>
        <div v-if="profilePic">
          <img :src="profilePic" alt="Profile Picture" class="w-[100px] rounded-8" />
        </div>
        <div
          v-if="!showDialogBool && !profilePic"
          class="text-13 text-primary-500 cursor-pointer hover:text-primary-600"
          @click="showDialog()"
        >
          Upload picture
        </div>
        <input
          v-show="showDialogBool"
          id="profilePic"
          ref="profilePicInput"
          class="m-8"
          type="file"
          accept=".png, .jpg, .jpeg"
          @change="onPicChange"
        />
      </div>
    </div>
    <div class="border-b border-gray-50 flex gap-8">
      <div class="text-14 pl-20 pt-20 w-1/5">
        <div>Subscriptions</div>
        <div class="text-13 text-primary-500 cursor-pointer hover:text-primary-600" @click="openModal()">Add</div>
      </div>
      <div class="my-8 text-13 ml-12 flex flex-col w-2/5 border border-gray-50 rounded-8">
        <div v-for="subscription in subscriptions" :key="subscription.uid">
          <Subscription :subscription="subscription" @edit="openModalToEdit($event)" />
        </div>
      </div>
    </div>
    <SubscriptionModal :show="showModal" :subscription-to-edit="subscriptionToEdit" @close="closeModal()" />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

import Subscription from './components/Subscription.vue';
import SubscriptionModal from './components/SubscriptionModal.vue';

export default {
  name: 'UserAccount',

  components: { Subscription, SubscriptionModal },

  data() {
    return {
      showModal: false,
      showDialogBool: false,
      subscriptionToEdit: { type: Object, default: () => ({}) },
    };
  },

  computed: {
    ...mapState(['user', 'isAuthenticated', 'subscriptions']),
    ...mapState('application', ['axiosInstanceFileUpload', 'axiosInstance']),

    profilePic() {
      return this.user.profile_pic;
    },

    userId() {
      return this.user.user_id;
    },

    userRoleVerbose() {
      let userRole = this.user.user_role;
      if (this.user.is_staff) {
        userRole += ', staff';
      } else if (this.user.is_external) {
        userRole += ', external';
      } else if (this.user.is_usermanager) {
        userRole += ', user manager';
      }
      return userRole;
    },
  },

  mounted() {
    this.getSubscriptions();
    this.getSubscriptionTemplates();
    this.getSubscribers();
  },

  methods: {
    ...mapMutations(['setProfilePic']),
    ...mapActions(['getSubscriptions', 'getSubscriptionTemplates', 'getSubscribers']),

    closeModal() {
      this.showModal = false;
      this.subscriptionToEdit = null;
    },

    openModal() {
      this.showModal = true;
    },

    openModalToEdit(subscription) {
      this.showModal = true;
      this.subscriptionToEdit = subscription;
    },

    showDialog() {
      this.showDialogBool = true;
    },

    async onPicChange(e) {
      const file = e.target.files[0];
      if (!file) {
        return;
      }

      // Check file type
      const validImageTypes = ['image/jpeg', 'image/png'];
      if (!validImageTypes.includes(file.type)) {
        this.$root.notify('warning', 'Invalid file type', 'Please upload a JPEG or PNG image file.', 6000);
        return;
      }

      // Check file size
      const maxSize = 10 * 1024 * 1024; // 10MB
      if (file.size > maxSize) {
        this.$root.notify('warning', 'File too large', 'Maximum size is 10MB.', 6000);
        return;
      }
      let formData = new FormData();
      formData.append('image', file);
      this.axiosInstanceFileUpload
        .put(`/api/v1/user/profile-pic/${this.userId}/`, formData, {})
        .then(() => {
          // Assuming the response contains the new image URL
          this.fetchProfilePic();
          this.$root.notify('success', 'Profile Picture Updated', '', 3000);
          this.showDialogBool = false;
        })
        .catch(error => {
          this.$root.notify('warning', error.message, error.response.data.error_message, 6000);
        });
    },

    async fetchProfilePic() {
      try {
        const response = await this.axiosInstance.get(`/api/v1/user/profile-pic/${this.userId}/`, {
          responseType: 'blob',
        });
        if (response.data.type.startsWith('image/')) {
          const urlCreator = window.URL || window.webkitURL;
          let profilePic = urlCreator.createObjectURL(response.data);
          this.setProfilePic(profilePic);
        }
      } catch (error) {
        console.error('Failed to fetch profile picture:', error);
      }
    },

    async deleteProfilePic() {
      // Call the DELETE API endpoint
      this.axiosInstance
        .delete(`/api/v1/user/profile-pic/${this.userId}/`)
        .then(response => {
          // Check the success status
          if (response.data.status === 'success') {
            // Remove the profile picture from the UI
            this.setProfilePic('');
            this.$root.notify('success', 'Profile Picture Deleted', '', 3000);
            this.$refs.profilePicInput.value = '';
          } else {
            // Handle errors
            this.$root.notify('warning', 'Error', response.data.message, 3000);
          }
        })
        .catch(error => {
          let errorMessage =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : 'An error occurred';
          this.$root.notify('warning', 'Error', errorMessage, 3000);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-pic-big {
  width: 110px;
  height: 110px;
  border-radius: 50%;
}
</style>
