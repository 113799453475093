<template>
  <div class="part-properties mt-32">
    <div class="flex flex-col">
      <div class="flex flex-col justify-center items-center mb-24">
        <PartImage custom="!w-100 !h-100" :part="part" />
        <div :title="part.name" class="font-bold text-19 mt-24 max-w-[20rem] truncate" v-text="part.name" />
      </div>
      <ChainDescriptionOptions :part="part" @option-selected="optionSelected" />
      <div class="my-24">
        <div class="flex flex-col">
          <PrpCategoryInput required-categories :part="part" />
        </div>
      </div>

      <div v-if="hasPartDetails" lass="mt-20 text-13">
        <h1 class="font-semibold mb-16 text-13" v-text="'Properties'" />
        <table class="w-full text-13">
          <tr v-for="(property, key) in partDetails" :key="key" class="mb-16">
            <td class="text-left text text-gray-600">{{ property.label }}</td>
            <td class="m-auto text-right">
              {{ `${$formatTwoDecimalPlaces(property.value)} ${property.unit}` }}
            </td>
          </tr>
        </table>
      </div>

      <FileUploadModal :show="showFileUploadModal" @close="closeFileUploadModal" />
      <AddPropertyModal :show="showAddPropertyModal" @close="closeAddPropertyModal" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import SparkInput from '../../../components/SparkComponents/SparkInput.vue';

import ChainDescriptionOptions from './ChainDescriptionOptions.vue';
import AddPropertyModal from './modals/AddPropertyModal.vue';
import FileUploadModal from './modals/FileUploadModal.vue';
import PartImage from './PartImage.vue';
import PrpCategoryInput from './PrpCategoryInput.vue';

export default {
  name: 'PartProperties',

  components: { PartImage, SparkInput, AddPropertyModal, FileUploadModal, ChainDescriptionOptions, PrpCategoryInput },

  props: {
    part: { type: Object, default: () => {} },
  },

  emits: ['set-component', 'option-selected'],

  data() {
    return {
      showAddPropertyModal: false,
      showFileUploadModal: false,
      partName: '',
      partDetails: {},
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),

    hasPartDetails() {
      return Object.keys(this.partDetails).length > 0;
    },
  },

  watch: {
    part: {
      handler() {
        this.fetchPartDetails();
        this.partName = this.part.name;
      },

      immediate: true,
      deep: true,
    },

    reloadPart() {
      this.fetchPartDetails();
    },
  },

  methods: {
    closeAddPropertyModal() {
      this.showAddPropertyModal = false;
    },

    optionSelected(data) {
      this.$emit('option-selected', data);
    },

    closeFileUploadModal() {
      this.showFileUploadModal = false;
    },

    async updatePartName() {
      const formData = { name: this.partName };
      this.axiosInstance.put(`/api/v1/part/${this.part.part_id}/`, formData).catch(error => {
        console.log(JSON.stringify(error));
      });
    },

    async fetchPartDetails() {
      try {
        const response = await this.axiosInstance.get(`/api/v1/prp-part/${this.part.part_id}/`);
        const processChainProperties = response.data.processChainProperties;
        const partProperties = response.data.partProperties;
        this.partDetails = { ...partProperties, ...processChainProperties };
      } catch (error) {
        console.error('Error fetching part details:', error);
      }
    },
  },
};
</script>
