<template>
  <SparkModal dismissable :model-value="show" @update:model-value="close">
    <template #header>
      <div class="flex flex-col">
        <div>Download Template</div>
      </div>
    </template>
    <template #default>
      <TypeSelection @set-active-template-type="setActiveTemplateType($event)" />

      <!-- Buttons at the bottom -->
      <div class="flex gap-8 my-8 justify-end">
        <SparkButton variant="outlined" @click="close"><div v-text="'Cancel'" /></SparkButton>
        <SparkButton variant="secondary" @click="downloadDefaultTemplateDocx()"
          ><div v-text="'Download'"
        /></SparkButton>
      </div>
    </template>
  </SparkModal>
</template>

<script>
import { saveAs } from 'file-saver';
import { mapState, mapActions } from 'vuex';

import TypeSelection from './TypeSelection.vue';

import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkModal from '@/components/SparkComponents/SparkModal.vue';

export default {
  name: 'AddDocumentModal',

  components: {
    SparkModal,
    SparkButton,
    TypeSelection,
  },

  props: {
    show: { type: Boolean },
  },

  emits: ['close', 'select'],

  data() {
    return {
      activeTemplateType: 'part',
    };
  },

  computed: {
    ...mapState('application', ['axiosInstanceFileUpload', 'axiosInstance']),
  },

  watch: {},

  methods: {
    close() {
      this.$emit('close');
    },

    setActiveTemplateType(type) {
      this.activeTemplateType = type;
    },

    async downloadDefaultTemplateDocx() {
      this.axiosInstance
        .get(`api/v1/doc-default-template/${this.activeTemplateType}/`, {
          responseType: 'blob',
        })
        .then(response => {
          if (this.activeTemplateType === 'part') {
            saveAs(response.data, 'spark_template_v4.docx');
          } else if (this.activeTemplateType === 'part_list') {
            saveAs(response.data, 'spark_list_template_v0.docx');
          }
          this.close();
        })
        .catch(error => {
          console.log(error);
        });
    },
  },
};
</script>
